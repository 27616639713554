import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faAngleDoubleLeft, faAngleDoubleRight, faUmbrellaBeach, faMoneyBill1Wave, faGraduationCap, faPersonRunning } from '@fortawesome/free-solid-svg-icons'
import {
    fetchAllWorklogsFromBackend as fetchAllWorklogsFromBackendAction,
    fetchAllWorklogsFromTempo as fetchAllWorklogsFromTempoAction,
} from "../../actions/worklogActions";
import { State } from "../../types";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { DayWithWorklogs } from "../../types/worklog";
import { dateToDateString, displayCumulativeHours, displayCumulativeNumber, displayHours, getBillablePercentage, getLiikuntaHours, getOpiskeluHours } from "../../helpers";

interface Props {
}
const Daily: React.FC<Props> = () => {
    // const settings: any = useSelector((store: any) => store.settings);
    const inProgress: any = useSelector((store: State) => store.inProgress);
    const worklogs: State["worklogs"] = useSelector((store: any) => store.worklogs);
    const dispatch: any = useDispatch();

    const [dayOffset, setDayOffset] = useState(1);

    const getDayWithWorklog = (offset: number): DayWithWorklogs | null => {
        if (worklogs.myWorklogs.length <= offset + 1) {
            return null;
        }
        return worklogs.myWorklogs[0 + dayOffset]
    }

    const getDayWithWorklogByDate = (dateString: string): DayWithWorklogs | null => {
        return worklogs.myWorklogs.find(d => d.day === dateString) ?? null;
    }
    const currentDay = getDayWithWorklog(dayOffset);

    useEffect(() => {
        if (worklogs.myWorklogs.length === 0) {
            dispatch(fetchAllWorklogsFromBackendAction());
        }
    }, [worklogs.myWorklogs]);

    return (
        <>
            <div className="p-2 box-grey">
                <div className="mb-3">
                    {/* <h5>Daily</h5> */}
                    <span className="text-orange">{getDayWithWorklog(dayOffset)?.day ?? "-"}</span>
                    <span className="ms-2 text-muted text-small">{dayOffset === 0 && "(today)"}{dayOffset === 1 && "(yesterday)"}</span>
                    {currentDay?.isWorkingDay === false ? <span className="ms-2 text-muted text-small"><FontAwesomeIcon icon={faUmbrellaBeach} className="me-2" />Non-business day</span> : null}
                    <br />
                    <Row>
                        <Col>
                            <span>Worktime (+/-)</span><br />
                            <span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayHours(currentDay?.totalHours ?? "-")}</span>
                            <span className="ms-2"></span>
                            (<span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayCumulativeHours(currentDay?.deltaHours ?? "-")}</span>)
                        </Col>
                        <Col>
                            <span>Cumulative</span><span className="ms-2 text-muted">(after)</span><br />
                            <span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayCumulativeHours(currentDay?.cumulativeDeltaHours ?? "-")}</span>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <FontAwesomeIcon icon={faMoneyBill1Wave} size={"1x"} className="me-2 text-muted" title="Billable" />
                            <span className="text-orange">{currentDay ? getBillablePercentage([currentDay]) : ""}</span> %
                            <span className="text-small text-muted d-block">Billable (%)</span>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faGraduationCap} size={"1x"} className="me-2 text-muted" title="Studying" />
                            <span className="text-orange">{currentDay ? getOpiskeluHours([currentDay]) : ""}</span>
                            <span className="text-small text-muted d-block">Studying (h)</span>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faPersonRunning} size={"1x"} className="me-2 text-muted" title="Sports" />
                            <span className="text-orange">{currentDay ? getLiikuntaHours([currentDay]) : ""}</span>
                            <span className="text-small text-muted d-block">Sports (h)</span>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col>
                        <Button onClick={() => { setDayOffset(dayOffset + 1) }} variant="secondary" className="px-1 py-0 w-100 h-100">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} size={"1x"} />
                        </Button>
                    </Col>
                    <Col style={{ whiteSpace: "nowrap" }}>
                        <div className="text-center ">
                            {/* <button className="btn btn-link px-2 py-1" style={{ color: "#EF4119" }} onClick={openWorklogs}>Open Worklogs</button> */}
                        </div>
                    </Col>
                    <Col>
                        <Button disabled={dayOffset <= 0} onClick={() => { setDayOffset(dayOffset - 1) }} variant="secondary" className="px-1 py-0 w-100 h-100">
                            <FontAwesomeIcon icon={faAngleDoubleRight} size={"1x"} />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Daily;