import { AnyAction } from "redux";
import { actions } from ".";
import api from "../api";

export const fetchAllWorklogsFromBackend = () => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        dispatch(actions.inProgressAction("fetchAllWorklogsFromBackend"));
        try {
            let res = await api.get("/api/fetchMyWorklogs/all");
            dispatch(actions.setMyWorklogsAction(res.data));
        } catch (e) { }
        dispatch(actions.notInProgressAction("fetchAllWorklogsFromBackend"));
    }
}

export const fetchAllWorklogsFromTempo = () => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        dispatch(actions.inProgressAction("fetchAllWorklogsFromTempo"));
        try {
            await api.get("/api/fetchMyWorklogsFromTempo/all");
            let res = await api.get("/api/fetchMyWorklogs/all");
            dispatch(actions.setMyWorklogsAction(res.data));
        } catch (e) { }
        dispatch(actions.notInProgressAction("fetchAllWorklogsFromTempo"));
    }
}

// export const fetchWorklogStatus = () => {
//     return async (dispatch: (arg0: AnyAction) => void) => {
//         dispatch(actions.inProgressAction("fetchWorklogStatus"));
//         try {
//             let res = await api.get("/api/fetchMyWorklogStatus");
//             dispatch(actions.setMyWorklogStatusAction(res.data));
//         } catch (e) { }
//         dispatch(actions.notInProgressAction("fetchWorklogStatus"));
//     }
// }
