import { UserStatus } from "../types";
import { BillingData } from "../types/billing";
import { DayWithWorklogs, Worklog } from "../types/worklog";

const getRandom32Hex = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const hexString = array[0].toString(16);
    const paddedHexString = hexString.padStart(8, '0');
    return paddedHexString;
}

export const getRandomInstallationId = () => {
    let r = "";
    for (let i = 0; i < 8; i++) {
        r += getRandom32Hex()
    }
    return r;
}

export const displayCumulativeNumber = (n: number | string): string => {
    if (typeof n === "number") {
        return (n >= 0) ? `+${n}` : `${n}`;
    }
    return n;
};

export const displayHours = (n: number | string): string => {
    if (typeof n === "number") {
        const h = `${n}`.split(".")[0];
        const min = Math.round(parseFloat("0." + `${n}`.split(".")[1] ?? "0") * 60);
        return `${h}:${`0${min}`.slice(-2)}`;
    }
    return n;
};

export const displayCumulativeHours = (n: number | string): string => {
    if (typeof n === "number") {
        const prefix = n >= 0 ? "+" : "";
        const h = `${n}`.split(".")[0];
        const min = Math.round(parseFloat("0." + `${n}`.split(".")[1] ?? "0") * 60);
        return `${prefix}${h}:${`0${min}`.slice(-2)}`;
    }
    return n;
};

export const dateToDateString = (d: Date): string => {
    return `${d.getFullYear()}-` + `0${d.getMonth() + 1}`.slice(-2) + "-" + `0${d.getDate()}`.slice(-2);
}

export const isBillable = (worklog: Worklog): boolean => {
    const nonBillableProjects = ["TIP", "DWH", "PP", "SALES", "SIS", "RTRO", "TRI"];
    const billableInternalTickets = ["TRI-29", "TRI-32", "TRI-33"];
    const projectKey = worklog.ticketKey?.split("-")[0] ?? "";
    if (projectKey === "TRI") {
        if (billableInternalTickets.includes(worklog.ticketKey)) {
            return true;
        }
        return false;
    }
    if (nonBillableProjects.includes(projectKey)) {
        return false;
    }
    return true;
}

export const getBillablePercentage = (days: DayWithWorklogs[]): string => {
    let billable = 0;
    let total = 0;
    for (let day of days) {
        for (let worklog of day.worklogs) {
            if (isBillable(worklog)) {
                billable += worklog.durationSeconds;
            }
            total += worklog.durationSeconds;
        }
    }
    if (!total) {
        return "-";
    }
    return `${Math.round((billable / total) * 100)}`;
}

export const getLiikuntaHours = (days: DayWithWorklogs[]): string => {
    let total = 0;
    for (let day of days) {
        for (let worklog of day.worklogs) {
            if (worklog.ticketKey === "TRI-5") {
                total += worklog.durationSeconds;
            }
        }
    }
    return displayHours(total / 3600);
}

export const getOpiskeluHours = (days: DayWithWorklogs[]): string => {
    let total = 0;
    for (let day of days) {
        for (let worklog of day.worklogs) {
            if (worklog.ticketKey === "TRI-7") {
                total += worklog.durationSeconds;
            }
        }
    }
    return displayHours(total / 3600);
}

export const getMaxOpiskeluHours = (userStatus: UserStatus): string => {
    if (userStatus.hoursPerDay >= 7.5) {
        return String(2)
    }
    if (userStatus.hoursPerDay > 0 && userStatus.hoursPerDay < 7.5) {
        return String(1);
    }
    return "";
}

export const displayPrice = (price: number | undefined): string => {
    if (!price) {
        price = 0;
    }
    return `${numberWithSpacesAndTwoDecimals(price)}`.replace(".", ",");
}

export const getTotalEur = (b: BillingData): number => {
    let t = 0;
    for (const c of b.customers) {
        t += c.totalPrice ?? 0;
    }
    return t;
}

export const getTotalSeconds = (b: BillingData): number => {
    let tSec = 0;
    for (const c of b.customers) {
        for (const p of c.projects) {
            for (const r of p.billingRows) {
                tSec += r.timeSeconds ?? 0;
            }
        }
    }
    return tSec;
}

export const displayHoursDecimal = (hours: number) => {
    return numberWithSpaces(Math.floor(hours / 0.25) * 0.25).replace(".", ",")
}

export const displayHoursFromSeconds = (seconds: number) => {
    return displayHours(seconds / 3600)
}

const numberWithSpaces = (x: number): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const numberWithSpacesAndTwoDecimals = (x: number): string => {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}