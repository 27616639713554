import axios from "axios";
import { getAppInstallationId } from "../helpers/authorizationHelper";

let baseURL = '/';
if (window.location.host.includes("localhost")) {
    baseURL = `http://localhost:8000/`;
}

export default axios.create({
    baseURL: baseURL,
    validateStatus: () => true,
    headers: {
        appinstallationid: getAppInstallationId(),
    }
});
