import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Note } from "../../types/billing";
import { faInfoCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

// Main component
interface Props {
    note: Note
}
const NoteComponent: React.FC<Props> = (props) => {

    const infoColor = "rgb(79, 195, 247)";
    const dangerColor = "rgb(239, 65, 25)";

    const getColor = (note: Note): string => {
        if (note.type === "danger") {
            return dangerColor
        }
        return infoColor
    }

    const getIcon = (note: Note): JSX.Element => {
        if (note.type === "danger") {
            return <FontAwesomeIcon className="me-1" icon={faExclamationTriangle} style={{color: getColor(note)}} />
        }
        return <FontAwesomeIcon className="me-1" icon={faInfoCircle} style={{color: getColor(note)}} />
    }

    return (
        <>
            { getIcon(props.note) }
            <span style={{color: getColor(props.note), fontSize: "10pt"}}>{props.note.title}</span>
        </>
    );
}

export default NoteComponent;