import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSettings } from '../actions';
import { AppSettings } from '../types';
import { fetchUserStatus } from '../actions/userActions';

function DataLoader(props: any) {

    const dispatch: any = useDispatch();
    const settings: AppSettings = useSelector((store: any) => store.settings)

    useEffect(() => {
        if (!settings.appInstallationId) {
            dispatch(loadSettings());
        } else {
            dispatch(fetchUserStatus());
        }
    });

    return (null)
}

export default DataLoader
