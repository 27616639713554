import { AnyAction } from "redux";
import { actions } from ".";
import api from "../api";

export const fetchBillingFromTempo = (year: number, month: number) => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        dispatch(actions.inProgressAction("fetchBillingFromTempo"));
        try {
            const res = await api.get(`/api/fetchbillingdatafromtempo/${year}/${month}`);
            dispatch(actions.updateBillingDataAction(res.data.billingData));
            dispatch(actions.updateBillingStatusAction({...res.data.status, currentYear: year, currentMonth: month, enableTempoFetch: false}));
        } catch (e) { }
        dispatch(actions.notInProgressAction("fetchBillingFromTempo"));
    }
}

export const getBillingData = (year: number, month: number) => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        try {
            const res = await api.get(`/api/getbillingdata/${year}/${month}`);
            dispatch(actions.updateBillingDataAction(res.data.billingData));
            dispatch(actions.updateBillingStatusAction({...res.data.status, currentYear: year, currentMonth: month, enableTempoFetch: true}));
        } catch (e) { }
    }
}