import { useDispatch, useSelector } from "react-redux";
import {
    Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMask, faBars, faHouseUser, faGear, faCircleInfo, faSync, faUserSlash, faUser, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import React from "react";
import { State } from "../../types";
import { openApplicationWindow } from "../../actions";
import { userHasPermission } from "../../helpers/authorizationHelper";

function Header() {
    // const settings = useSelector((store: State) => store.settings)
    const userStatus = useSelector((store: State) => store.userStatus)
    const inProgress = useSelector((store: State) => store.inProgress);
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="px-2 header" style={{
                height: "50px",
                lineHeight: "50px",
                // ...Box,
                // ...BoxRed01,
                backgroundColor: "#303030"
            }}>
                <div className="dropdown">
                    <>
                        <button
                            className="border-0 btn btn-theme-red round menubutton"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ overflowY: "hidden" }}
                        >
                            <FontAwesomeIcon className="text-orange glow" icon={faBars} size="lg" />
                            {/* <img className="ms-0" src={TriLogo} style={{ width: "26px", height: "26px" }} /> */}
                        </button>
                    </>
                    <ul className="dropdown-menu dropdown-theme-grey animate slideIn w-100" aria-labelledby="dropdownMenuButton1" style={{ lineHeight: 1.5 }}>
                        <li><Link className="dropdown-item px-2 py-1" to="./"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faHouseUser} /></span><span className="">Home</span></Link></li>
                        <li><Link className="dropdown-item px-2 py-1" to="./worklogs"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faClock} /></span><span className="">Worklogs</span></Link></li>
                        { userHasPermission(userStatus, "billing") && <li><Link className="dropdown-item px-2 py-1" to="./billing"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faFileInvoiceDollar} /></span><span className="">Billing</span></Link></li>}
                        <li><Link className="dropdown-item px-2 py-1" to="./settings"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faGear} /></span><span className="">Settings</span></Link></li>
                        <li><Link className="dropdown-item px-2 py-1" to="./about"><span className="d-inline-block" style={{ width: "25px" }}><FontAwesomeIcon icon={faCircleInfo} /></span><span className="">About</span></Link></li>
                    </ul>

                    <span className="float-end px-1">
                        {
                            userStatus.isImpersonating && <FontAwesomeIcon onClick={() => { dispatch(openApplicationWindow("user")) }} className="ms-2" icon={faMask} size={"1x"} />
                        }
                        {!userStatus.installationActive ?
                            <FontAwesomeIcon onClick={() => { dispatch(openApplicationWindow("login")) }} className="ms-2 text-danger" icon={faUserSlash} size={"1x"} />
                            :
                            <FontAwesomeIcon onClick={() => { dispatch(openApplicationWindow("user")) }} className="ms-2" icon={faUser} size={"1x"} />
                        }
                    </span>
                    <span className="float-end px-1">
                        {inProgress.length > 0 ? <FontAwesomeIcon className="ms-2" icon={faSync} size={"1x"} spin={true} /> : null}
                    </span>
                </div>

            </div>
        </>
    );
}

export default Header;