import { displayHoursDecimal, displayPrice, getTotalEur, getTotalSeconds } from "../../helpers";
import { textPrice } from "../../styles/styles";
import { BillingData } from "../../types/billing";

// Main component
interface Props {
    billingData: BillingData
}
const Summary: React.FC<Props> = (props) => {
    return (
        <>
        <div className="p-2 mb-2 box-grey">
            <h4 className="mb-0">Summary</h4>
            <div className="row">
                <div className="col-6 col-md-2">
                    Total billing
                    {/* <span style={textPrice}>€</span> */}
                </div>
                <div className="col-6 col-md-2">
                    <span style={textPrice}>{displayPrice(getTotalEur(props.billingData))}</span> €
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-2">
                    Total work
                    {/* <span style={textPrice}>h</span> */}
                </div>
                <div className="col-6 col-md-2">
                    <span style={textPrice}>{displayHoursDecimal(getTotalSeconds(props.billingData) / 3600)}</span> h
                </div>
            </div>
        </div>
        </>
    );
}

export default Summary;