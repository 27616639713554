import { actions, displayResponseError } from ".";
import api from "../api";
import { fetchAllWorklogsFromBackend } from "./worklogActions";

export const login = (emailAddress: string, password: string) => {
    return async (dispatch: (arg0: any) => void) => {
        try {
            let resLogin = await api.post("/api/login", { emailAddress, password });
            if (resLogin.status !== 200) {
                dispatch(displayResponseError(resLogin));
            }
            let resUserStatus = await api.get("/api/userstatus");
            dispatch(actions.updateUserStatusAction(resUserStatus.data));
        } catch (e) { }
    }
}

export const resetPassword = (emailAddress: string, code: string, password1: string, password2: string) => {
    return async (dispatch: (arg0: any) => void) => {
        try {
            let resLogin = await api.post(`/api/resetpassword/${code}`, { emailAddress, password1: password1, password2: password2 });
            if (resLogin.status !== 200) {
                dispatch(displayResponseError(resLogin));
            } else {
                alert("Password changed");
            }
            let resUserStatus = await api.get("/api/userstatus");
            dispatch(actions.updateUserStatusAction(resUserStatus.data));
        } catch (e) { }
    }
}

export const logout = () => {
    return async (dispatch: (arg0: any) => void) => {
        try {
            await api.post("/api/logout");
            let resUserStatus = await api.get("/api/userstatus");
            dispatch(actions.updateUserStatusAction(resUserStatus.data));
        } catch (e) { }
    }
}

export const fetchUserStatus = () => {
    return async (dispatch: (arg0: any) => void) => {
        try {
            let res = await api.get("/api/userstatus");
            dispatch(actions.updateUserStatusAction(res.data));
        } catch (e) { }
    }
}

export const impersonate = (emailAddress: string) => {
    return async (dispatch: (arg0: any) => void) => {
        try {
            let resImpersonate = await api.post("/api/impersonate", { emailAddress });
            if (resImpersonate.status !== 200) {
                dispatch(displayResponseError(resImpersonate));
            }
            let resStatus = await api.get("/api/userstatus");
            dispatch(actions.updateUserStatusAction(resStatus.data));
            dispatch(fetchAllWorklogsFromBackend());
        } catch (e) { }
    }
}

export const unimpersonate = () => {
    return async (dispatch: (arg0: any) => void) => {
        try {
            let resImpersonate = await api.post("/api/unimpersonate");
            if (resImpersonate.status !== 200) {
                dispatch(displayResponseError(resImpersonate));
            }
            let resStatus = await api.get("/api/userstatus");
            dispatch(actions.updateUserStatusAction(resStatus.data));
            dispatch(fetchAllWorklogsFromBackend());
        } catch (e) { }
    }
}
