import { useDispatch } from "react-redux";
import { openApplicationWindow } from "../../actions";
import { Button } from "react-bootstrap";


interface Props {
}
const Desktop: React.FC<Props> = () => {
    const dispatch: any = useDispatch();

    const openLoginModal = () => {
        dispatch(openApplicationWindow("login"));
    }

    return (
        <>
            <div className="py-2">
                {/* <h4>You are not logged in.</h4> */}
                <Button onClick={openLoginModal} className="px-2 py-0" variant="danger">Log in</Button>
            </div>
        </>
    );
}

export default Desktop;