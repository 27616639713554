export const Box = {
    borderStyle: "solid",
    borderWidth: "1px",
    // borderColor: "#BF360C",
    // backgroundColor: "#601B06",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
}

export const BoxRed01 = {
    borderColor: "#601B06",
    borderWidth: "0",
    backgroundColor: "#300E03",
}

export const textOrange = {
    color: "#EF4119",
}

export const textInfo = {
    color: "#4FC3F7"
}

export const boxInfo = {
    border: "1px solid rgba(79, 195, 247, 0.5)",
    color: "#4FC3F7",
    borderRadius: "4px"
}

export const textPrice = {
    ...textOrange,
    fontWeight: "bold",
}