import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faUmbrellaBeach, faMask } from '@fortawesome/free-solid-svg-icons'
import {
    fetchAllWorklogsFromBackend as fetchAllWorklogsFromBackendAction,
    fetchAllWorklogsFromTempo as fetchAllWorklogsFromTempoAction,
} from "../../actions/worklogActions";
import { State } from "../../types";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { DayWithWorklogs } from "../../types/worklog";
import { displayCumulativeNumber } from "../../helpers";
import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";

interface Props {
}
const Worklogs: React.FC<Props> = () => {
    // const settings: any = useSelector((store: any) => store.settings);
    const userStatus = useSelector((store: State) => store.userStatus)
    const inProgress: any = useSelector((store: State) => store.inProgress);
    const worklogs: State["worklogs"] = useSelector((store: any) => store.worklogs);
    const dispatch: any = useDispatch();

    const fetchAllWorklogsFromTempo = () => {
        dispatch(fetchAllWorklogsFromTempoAction())
    }

    const [displayPeriod, setDisplayPeriod] = useState("daily");

    const [showWorklogs, setShowWorklogs] = useState(false);
    const openWorklogs = () => setShowWorklogs(true);
    const closeWorklogs = () => setShowWorklogs(false);

    useEffect(() => {
        if (worklogs.myWorklogs.length === 0) {
            dispatch(fetchAllWorklogsFromBackendAction());
        }
    }, [dispatch, worklogs.myWorklogs]);

    const workLogDayElement = (w: DayWithWorklogs) => {
        return (
            <Row key={w.day} style={{ opacity: w.isWorkingDay ? 1 : 0.5 }}>
                <Col style={{ whiteSpace: "nowrap" }}>
                    {!w.isWorkingDay ? <FontAwesomeIcon className="me-1" style={{ color: "#9E9E9E" }} icon={faUmbrellaBeach} /> : null}
                    <span style={{ fontSize: "10pt", color: w.isWorkingDay ? "#EF4119" : "#9E9E9E" }}>{w.day}</span>
                </Col>
                <Col style={{ whiteSpace: "nowrap" }}>
                    <span className="me-2" style={{ fontWeight: "bold", color: "#EF4119" }}>{w.totalHours}</span>
                    (<span style={{ color: "#EF4119" }}>{displayCumulativeNumber(w.deltaHours ?? "-")}</span>)
                </Col>
                <Col style={{ whiteSpace: "nowrap" }}>
                    <span style={{ color: "#EF4119" }}>{displayCumulativeNumber(w.cumulativeDeltaHours ?? "-")}</span> h
                </Col>
            </Row>
        );
    }

    return (
        <>
            <div className="py-2">
                <h4>Worklogs</h4>

                {userStatus.isImpersonating && <Alert variant="dark px-2 py-2 text-small">
                    <FontAwesomeIcon className="me-2" icon={faMask} size={"1x"} />
                    {userStatus.isImpersonating}
                </Alert>}

                <div className="mb-2">
                    <Button variant="link" style={{ textDecoration: "none" }} onClick={() => { setDisplayPeriod("daily") }}>
                        <span className={`mx-1 ${displayPeriod === "daily" ? 'text-orange glow' : 'text-grey'}`}>Daily</span>
                    </Button>
                    <Button variant="link" style={{ textDecoration: "none" }} onClick={() => { setDisplayPeriod("weekly") }}>
                        <span className={`mx-1 ${displayPeriod === "weekly" ? 'text-orange glow' : 'text-grey'}`}>Weekly</span>
                    </Button>
                    <Button variant="link" style={{ textDecoration: "none" }} onClick={() => { setDisplayPeriod("monthly") }}>
                        <span className={`mx-1 ${displayPeriod === "monthly" ? 'text-orange glow' : 'text-grey'}`}>Monthly</span>
                    </Button>
                </div>

                {displayPeriod === "daily" ? <Daily /> : null}
                {displayPeriod === "weekly" ? <Weekly /> : null}
                {displayPeriod === "monthly" ? <Monthly /> : null}

            </div>

            <Button
                className="mb-2 mt-3 w-100"
                variant="secondary"
                onClick={openWorklogs}
            >
                Open worklogs
            </Button>

            <Button
                className="mb-2 mt-3 w-100"
                variant="danger"
                disabled={inProgress.indexOf("fetchAllWorklogsFromTempo") > -1}
                onClick={fetchAllWorklogsFromTempo}
            >
                Fetch all worklogs from Tempo
                {inProgress.indexOf("fetchAllWorklogsFromTempo") > -1 ? <FontAwesomeIcon className="ms-2" icon={faSync} size={"1x"} spin={true} /> : null}
            </Button>

            <Modal show={showWorklogs} onHide={closeWorklogs}>
                <Modal.Header className="p-1" closeButton>
                    <Modal.Title>Worklogs</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    <Row key={0} style={{ fontSize: "10pt", fontWeight: "bold" }}>
                        <Col>
                            Day
                        </Col>
                        <Col>
                            Hours (+/-)
                        </Col>
                        <Col>
                            Cumulative
                        </Col>
                    </Row>
                    {
                        worklogs.myWorklogs.map(w => { return workLogDayElement(w) })
                    }
                </Modal.Body>
                <Modal.Footer className="p-1">
                    <Button className="px-2 py-1" variant="secondary" onClick={closeWorklogs}>
                        Close
                    </Button>
                    {/* <Button className="px-2 py-1" variant="primary" onClick={closeWorklogs}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default Worklogs;