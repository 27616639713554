import { getRandomInstallationId } from ".";
import { UserStatus } from "../types";

export const getAppInstallationId = (): string => {
    const url = window.location.hash.split('/');
    let appInstallationId = "";
    if (url.length > 2) {
        appInstallationId = url[2]
        if (appInstallationId === "no-installation-id") {
            const localId = localStorage.getItem("appInstallationId");
            if (localId !== null) {
                appInstallationId = localId;
            } else {
                appInstallationId = getRandomInstallationId();
                localStorage.setItem("appInstallationId", appInstallationId);
            }
        }
    }
    return appInstallationId;
}

export const userHasPermission = (user: UserStatus, request: string): boolean => {
    try {
        if (user.role === "SuperAdmin") {
            return true;
        }
        switch (request) {
            case "worklogs":
                return true;
            case "billing":
                if (user.role === "Admin") {
                    return true;
                }
                if (user.permissions.includes("billing")) {
                    return true;
                }
                return false;
            case "impersonate":
                if (user.permissions.includes("impersonate")) {
                    return true;
                }
                return false;
            case "devtools":
                if (user.permissions.includes("devtools")) {
                    return true;
                }
                return false;
        }
    } catch(e) {
        return false;
    }
    return false;
}