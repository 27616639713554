import { AnyAction } from "redux";
import { actions } from ".";
import api from "../api";

export const sleepAction3 = () => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        dispatch(actions.inProgressAction("sleepAction3"));
        try {
            let res = await api.get("/api/sleep/3");
        } catch (e) { }
        dispatch(actions.notInProgressAction("sleepAction3"));
    }
}

export const sleepAction5 = () => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        dispatch(actions.inProgressAction("sleepAction5"));
        try {
            let res = await api.get("/api/sleep/5");
        } catch (e) { }
        dispatch(actions.notInProgressAction("sleepAction5"));
    }
}

export const listUserIdsAction = () => {
    return async (dispatch: (arg0: AnyAction) => void) => {
        try {
            let res = await api.get("/api/logTempoUsers");
        } catch (e) { }
    }
}
