import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faGraduationCap, faPersonRunning, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import {
    fetchAllWorklogsFromBackend as fetchAllWorklogsFromBackendAction,
    fetchAllWorklogsFromTempo as fetchAllWorklogsFromTempoAction,
} from "../../actions/worklogActions";
import { State } from "../../types";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { DayWithWorklogs } from "../../types/worklog";
import { dateToDateString, displayCumulativeHours, displayCumulativeNumber, displayHours, getBillablePercentage, getLiikuntaHours, getMaxOpiskeluHours, getOpiskeluHours } from "../../helpers";

interface Props {
}
const Weekly: React.FC<Props> = () => {
    const userStatus = useSelector((store: State) => store.userStatus);
    const worklogs = useSelector((store: State) => store.worklogs);
    const dispatch: any = useDispatch();

    const [weekOffset, setWeekOffset] = useState(1);

    const getDayWithWorklogByDate = (dateString: string): DayWithWorklogs | null => {
        return worklogs.myWorklogs.find(d => d.day === dateString) ?? null;
    }

    const getWeekWithWorklog = (offset: number): DayWithWorklogs[] => {
        let now = new Date();
        let thisMonday = new Date(now.setDate(now.getDate() - now.getDay() + 1));
        thisMonday.setDate(thisMonday.getDate() - (7 * weekOffset));
        const week: DayWithWorklogs[] = [];
        for (let i = 0; i < 7; i++) {
            let currentDay = new Date(new Date(thisMonday).setDate(thisMonday.getDate() + i));
            let d = getDayWithWorklogByDate(dateToDateString(currentDay));
            if (d) {
                week.push(d);
            }
        }
        return week;
    }
    const currentWeek: DayWithWorklogs[] = getWeekWithWorklog(weekOffset);

    const getTotalHoursOfWeek = (currentWeek: DayWithWorklogs[]): number => {
        let total = 0;
        for (let d of currentWeek) {
            total += d.totalHours ?? 0;
        }
        return total;
    }

    const getDeltaHoursOfWeek = (currentWeek: DayWithWorklogs[]): number => {
        let delta = 0;
        for (let d of currentWeek) {
            delta += d.deltaHours ?? 0;
        }
        return delta;
    }

    const getWeekNumber = (d: Date): number => {
        let currentDate = new Date(d);
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
        var weekNumber = Math.ceil(days / 7);
        if (isNaN(weekNumber)) {
            return 0;
        }
        return weekNumber;
    }

    useEffect(() => {
        if (worklogs.myWorklogs.length === 0) {
            dispatch(fetchAllWorklogsFromBackendAction());
        }
    }, [worklogs.myWorklogs]);

    return (
        <>
            <div className="p-2 box-grey">
                <div className="mb-3">
                    {/* <h5>Weekly</h5> */}
                    <span>Week {getWeekNumber(new Date(currentWeek[0]?.day))}: </span><span className="text-orange text-small">{currentWeek[0]?.day ?? "-"}</span> - <span className="text-orange text-small">{currentWeek[currentWeek.length - 1]?.day ?? "-"}</span>
                    <span className="ms-2 text-muted text-small">{weekOffset === 0 && "(this week)"}{weekOffset === 1 && "(last week)"}</span>
                    <br />
                    <Row>
                        <Col style={{ whiteSpace: "nowrap" }}>
                            <span>Worktime (+/-)</span><br />
                            <span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayHours(getTotalHoursOfWeek(currentWeek) ?? "-")}</span>
                            <span className="ms-2"></span>
                            (<span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayCumulativeHours(getDeltaHoursOfWeek(currentWeek) ?? "-")}</span>)
                        </Col>
                        <Col style={{ whiteSpace: "nowrap" }}>
                            <span>Cumulative</span><span className="ms-2 text-muted">(after)</span><br />
                            <span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayCumulativeHours(currentWeek[currentWeek.length - 1]?.cumulativeDeltaHours ?? "-")}</span>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <FontAwesomeIcon icon={faMoneyBill1Wave} size={"1x"} className="me-2 text-muted" title="Billable" />
                            <span className="text-orange">{currentWeek ? getBillablePercentage(currentWeek) : ""}</span> %
                            <span className="text-small text-muted d-block">Billable (%)</span>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faGraduationCap} size={"1x"} className="me-2 text-muted" title="Studying" />
                            <span className="text-orange">{currentWeek ? getOpiskeluHours(currentWeek) : ""}</span> / {getMaxOpiskeluHours(userStatus)} h
                            <span className="text-small text-muted d-block">Studying (h)</span>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faPersonRunning} size={"1x"} className="me-2 text-muted" title="Sports" />
                            <span className="text-orange">{currentWeek ? getLiikuntaHours(currentWeek) : ""}</span> / 1 h
                            <span className="text-small text-muted d-block">Sports (h)</span>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col>
                        <Button onClick={() => { setWeekOffset(weekOffset + 1) }} variant="secondary" className="px-1 py-0 w-100 h-100">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} size={"1x"} />
                        </Button>
                    </Col>
                    <Col style={{ whiteSpace: "nowrap" }}>
                        {/* <div className="text-center ">
                                <button className="btn btn-link px-2 py-1" style={{ color: "#EF4119" }} onClick={openWorklogs}>Open Worklogs</button>
                            </div> */}
                    </Col>
                    <Col>
                        <Button disabled={weekOffset <= 0} onClick={() => { setWeekOffset(weekOffset - 1) }} variant="secondary" className="px-1 py-0 w-100 h-100">
                            <FontAwesomeIcon icon={faAngleDoubleRight} size={"1x"} />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Weekly;