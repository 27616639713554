import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";
import Desktop from "./Desktop";
import Header from "./Header";
import Worklogs from "./Worklogs";
import { State } from "../types";
import LoginPrompt from "./LoginPrompt";
import LoginModal from "./LoginModal";
import UserModal from "./UserModal";
import ResetPassword from "./ResetPassword";
import Billing from "./Billing";

function App() {
    const userStatus = useSelector((store: State) => store.userStatus);

    return (
        <>
            <div className="container p-0">
                <Router>
                    <Route exact path="/installed" ><Redirect to="/installed/0/" /></Route>
                    <Route path="/installed/:installationId/">
                        <Header />
                        <div className="px-2">
                            <Route exact path="/installed/:installationId/">
                                <Desktop />
                            </Route>
                            <Route exact path="/installed/:installationId/worklogs">
                                {userStatus.installationActive ? <Worklogs /> : <LoginPrompt />}
                            </Route>
                            <Route exact path="/installed/:installationId/settings">

                            </Route>
                            <Route exact path="/installed/:installationId/about">
                                <div className="py-2">
                                    <h4>About</h4>
                                    ...
                                </div>
                            </Route>
                            <Route exact path="/installed/:installationId/billing">
                                <Billing />
                            </Route>
                        </div>
                    </Route>
                    <Route exact path="/">
                        <div className="p-2">
                            <Modal show={true} onHide={() => { }} >
                                <Modal.Body>
                                    {/* <FontAwesomeIcon icon={faInfoCircle} className="me-2" /> */}
                                    <FontAwesomeIcon icon={faMobileScreen} className="me-2 text-orange" />
                                    Install web app on your mobile device by adding it to your Home Screen.
                                    <br />
                                    <br />
                                    <FontAwesomeIcon icon={faDesktop} className="me-2 text-orange" />
                                    To use the app in browser, use <a target="_blank" href={`/#/installed/no-installation-id/`} rel="noreferrer">this link</a>.
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Route>
                    <Route exact path="/resetpassword/:emailAddress/:code" >
                        <ResetPassword />
                    </Route>
                </Router>

                <LoginModal />
                <UserModal />

            </div >
        </>
    );
}

export default App;