import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import { State } from "../../types";
// import { UserStatus } from "../../types";
import { Button, Form } from "react-bootstrap";
import { fetchBillingFromTempo, getBillingData } from "../../actions/billingActions";
import BillingTable from "./BillingTable";
import Summary from "./Summary";
import { textPrice } from "../../styles/styles";

interface Props {
}
const Billing: React.FC<Props> = () => {
    // const settings: any = useSelector((store: State) => store.settings);
    // const userStatus: UserStatus = useSelector((store: State) => store.userStatus);
    const inProgress = useSelector((store: State) => store.inProgress);
    const billingData = useSelector((store: State) => store.billingData);
    const billingStatus = useSelector((store: State) => store.billingStatus);
    billingData.customers.sort((a,b) => (a.totalPrice ?? 0) - (b.totalPrice ?? 0)).reverse();
    const dispatch: any = useDispatch();

    const getThisMonth = (): { year: number, month: number } => {
        let now = new Date();
        let thisYear = now.getFullYear();
        let thisMonth = now.getMonth();
        if (now.getDate() < 10) {
            thisMonth -= 1;
            if (thisMonth === -1) {
                thisMonth = 11;
                thisYear -= 1;
            }
        }

        return ({ year: thisYear, month: thisMonth + 1 });
    }

    const [year, setYear] = useState<string>("");
    const [month, setMonth] = useState<string>("");

    const getYearMonth = (): { year: number, month: number } => {
        let y: number;
        let m: number;
        if (year !== "") {
            y = parseInt(year);
        } else {
            y = getThisMonth().year;
        }
        if (month !== "") {
            m = parseInt(month);
        } else {
            m = getThisMonth().month;
        }
        return { year: y, month: m }
    }

    const handleFetchBillingDataFromTempo = () => {
        const ym = getYearMonth()
        dispatch(fetchBillingFromTempo(ym.year, ym.month));
    }

    const handleGetBillingData = () => {
        const ym = getYearMonth()
        dispatch(getBillingData(ym.year, ym.month));
    }

    return (
        <div className="py-2">
            <h4>
                Billing
                {billingStatus.currentYear && <span className="ms-4" style={textPrice}>{billingStatus.currentMonth}/{billingStatus.currentYear}</span>}
                {billingStatus.lastUpdateSecondsAgo > -1 &&
                    <span className="ms-4 text-small">Timesheets fetched <span style={textPrice}>{Math.round(billingStatus.lastUpdateSecondsAgo/60)}</span> minutes ago</span>
                }
            </h4>
            <div className="row">
                <div className="col-6 col-md-2">Year</div>
                <div className="col-6 col-md-2 ps-0">Month</div>
            </div>
            <div className="row">
                <div className="col-6 col-md-2" style={{whiteSpace: "nowrap"}}>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        {/* <Form.Label>Year</Form.Label> */}
                        <Form.Control type="text" value={year} onChange={(e) => { setYear(e.target.value); }} placeholder={String(getThisMonth().year)} />
                    </Form.Group>
                </Form>
                </div>
                <div className="col-6 col-md-2 ps-0">
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {/* <Form.Label>Year</Form.Label> */}
                            <Form.Control type="text" value={month} onChange={(e) => { setMonth(e.target.value); }} placeholder={String(getThisMonth().month)} />
                        </Form.Group>
                    </Form>
                </div>
                <div className="col-2 d-none d-md-block"></div>
                <div className="col-12 col-md-3">
                    <Button
                        className="w-100 mb-2"
                        variant="secondary"
                        onClick={handleGetBillingData}
                    >
                        Get billing data
                    </Button>
                </div>
                <div className="col-12 col-md-3">
                    <Button
                        className="w-100 mb-2"
                        variant="danger"
                        disabled={inProgress.indexOf("fetchBillingFromTempo") > -1 || billingStatus.enableTempoFetch===false || (billingStatus.lastUpdateSecondsAgo > -1 && billingStatus.lastUpdateSecondsAgo < 60) }
                        onClick={handleFetchBillingDataFromTempo}
                    >
                        Fetch timesheets from Tempo
                        {inProgress.indexOf("fetchBillingFromTempo") > -1 ? <FontAwesomeIcon className="ms-2" icon={faSync} size={"1x"} spin={true} /> : null}
                    </Button>
                </div>
            </div>

            {
                billingData && <Summary billingData={ billingData } />
            }

            {
                billingData && <BillingTable billingData={ billingData } />
            }



        </div>
    );
}

export default Billing;