import { createAction } from '@reduxjs/toolkit'
import { AppSettings, State, UserStatus } from "../types";
// import { worklogActions } from "./worklogActions";
import { DayWithWorklogs, UserWorklogStatus } from "../types/worklog";
import { AxiosResponse } from "axios";
import { BillingData } from "../types/billing";
import { getAppInstallationId } from '../helpers/authorizationHelper';

export const actions = {
    updateSettingsAction: createAction<AppSettings>("UPDATE_SETTINGS"),
    openApplicationWindowAction: createAction<{ name: string }>("OPEN_APPLICATION_WINDOW"),
    closeApplicationWindowAction: createAction<{ name: string }>("CLOSE_APPLICATION_WINDOW"),
    updateUserStatusAction: createAction<UserStatus>("UPDATE_USER_STATUS"),
    inProgressAction: createAction<string>("IN_PROGRESS"),
    notInProgressAction: createAction<string>("NOT_IN_PROGRESS"),
    setMyWorklogsAction: createAction<DayWithWorklogs[]>("SET_MY_WORKLOGS"),
    setMyWorklogStatusAction: createAction<UserWorklogStatus>("SET_MY_WORKLOG_STATUS"),
    updateBillingDataAction: createAction<BillingData>("UPDATE_BILLING_DATA"),
    updateBillingStatusAction: createAction<State["billingStatus"]>("UPDATE_BILLING_STATUS"),
}

export const loadSettings = () => {
    return async (dispatch: (arg0: any) => void) => {
        const settings: AppSettings = {
            appInstallationId: getAppInstallationId(),
        }
        dispatch(actions.updateSettingsAction(settings));
    }
}

export const openApplicationWindow = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.openApplicationWindowAction({ name }));
    }
}

export const closeApplicationWindow = (name: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(actions.closeApplicationWindowAction({ name }));
    }
}

export const displayResponseError = (res: AxiosResponse<any, any>) => {
    return async (dispatch: (arg0: any) => void) => {
        alert(`Error ${res.status}: ${res.data ?? res.statusText}`);
    }
}