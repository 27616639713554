import { displayHoursDecimal, displayPrice } from "../../helpers";
import { boxInfo, textInfo, textPrice } from "../../styles/styles";
import { BillingData, BillingDataCustomer, BillingDataProject, BillingDataRow } from "../../types/billing";
import Note from "./Note";

// Main component
interface Props {
    billingData: BillingData
}
const BillingTable: React.FC<Props> = (props) => {
    return (
        <>
        { props.billingData.customers.map(c => <CustomerComponent customer={c} />) }
        </>
    );
}

export default BillingTable;


// Customer level
interface CustomerProps {
    customer: BillingDataCustomer
}
const CustomerComponent: React.FC<CustomerProps> = (props) => {
    return <>
        <div className="mb-2" style={{opacity: (props.customer.totalPrice ?? 0) > 0 ? 1 : 0.5}}>
            <div className="p-2 mb-2 box-grey">
                <span className="m-0 me-2 h5">{props.customer.name} </span>

                <span className="d-none d-md-inline-block">
                    { props.customer.customer?.note !== undefined && <Note note={props.customer.customer.note} /> }
                </span>

                {/* Float-end */}
                <span style={textPrice} className="float-end">{displayPrice(props.customer.totalPrice)}</span>

                <span className="d-none d-md-inline-block float-end mx-2">
                    { props.customer.customer?.reportLink && <a target="_blank" style={{...textInfo, fontSize: "10pt"}} href={props.customer.customer.reportLink} rel="noreferrer">Report</a> }
                </span>
            </div>
            { props.customer.projects.map(p => <ProjectComponent project={p} />) }
        </div>
    </>;
}

// Project level
interface ProjectProps {
    project: BillingDataProject
}
const ProjectComponent: React.FC<ProjectProps> = (props) => {
    return <>
        <div className="ms-1 ms-md-3 mb-2">
            <div className="p-2 box-grey">
                <span className="m-0 me-2">{props.project.name}</span>

                <span className="d-none d-md-inline-block">
                    { props.project.project?.note !== undefined && <Note note={props.project.project.note} /> }
                </span>

                {/* Float-end */}
                <span style={textPrice} className="float-end">{displayPrice(props.project.totalPrice)}</span>

                <span className="d-none d-md-inline-block float-end mx-2">
                    { props.project.project?.reportLink && <a target="_blank" style={{...textInfo, fontSize: "10pt"}} href={props.project.project.reportLink} rel="noreferrer">Report</a> }
                </span>

            </div>
            { props.project.billingRows.map(r => <RowComponent row={r} />) }
        </div>
    </>;
}

// Row level
interface RowProps {
    row: BillingDataRow
}
const RowComponent: React.FC<RowProps> = (props) => {
    return <>
            <div className="ms-1 ms-md-3">
                <div className="row" style={{whiteSpace: "nowrap"}}>
                    <div className="col-6">
                        <span className="m-0 text-small">{props.row.description}</span>
                        {props.row.note && <span style={{...boxInfo}} className="ms-2 text-small px-2">{props.row.note.title}</span>}
                    </div>
                    <div className="col-2 text-end d-none d-md-block">
                        {/* <span className="ms-4" style={textPrice}>{displayHoursDecimal(props.row.timeSeconds / 3600)}</span> h */}
                        <span className="ms-4" style={textPrice}>{displayHoursDecimal(props.row.timeSeconds / 3600)}</span> h
                    </div>
                    <div className="col-2 text-end d-none d-md-block">
                        <span className="ms-4" style={textPrice}>{props.row.pricePerHour}</span> €/h
                    </div>
                    <div className="col-6 col-md-2 text-end">
                        <span className="ms-4" style={textPrice}>{displayPrice(props.row.totalPrice)}</span> €
                    </div>
                </div>

            </div>
    </>;
}

