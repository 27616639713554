import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faClock, } from '@fortawesome/free-regular-svg-icons'
import { Link } from "react-router-dom";
import { State } from "../../types";
import { userHasPermission } from "../../helpers/authorizationHelper";

interface Props {
}
const Desktop: React.FC<Props> = () => {
    // const settings: any = useSelector((store: any) => store.settings);
    // const dispatch: any = useDispatch();
    const userStatus = useSelector((store: State) => store.userStatus)

    const btnClassName = "me-2"

    return (
        <>
            <div className="py-2 text-center">
                <h4>Home</h4>

                <div style={{display: "flex", flexDirection: "row"}}>

                    <Link to="./worklogs" style={{ textDecoration: "none" }} className={btnClassName}>
                        <div className="text-orange" style={{ width: "75px", height: "75px" }}>
                            <div className="box-grey p-3 text-center w-100 h-100">
                                <FontAwesomeIcon icon={faClock} size="2x" />
                            </div>
                            <span>
                                Worklogs
                            </span>
                        </div>
                    </Link>

                    {userHasPermission(userStatus, "billing") && <Link to="./billing" style={{ textDecoration: "none" }}>
                        <div className="text-orange" style={{ width: "75px", height: "75px" }}>
                            <div className="box-grey p-3 text-center w-100 h-100">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
                            </div>
                            <span>
                                Billing
                            </span>
                        </div>
                    </Link>}

                </div>


            </div>
        </>
    );
}

export default Desktop;