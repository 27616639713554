import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
// import { Dropdown } from "react-bootstrap";
import { State } from "../../types";
import { closeApplicationWindow } from "../../actions";
import { Button, Form, Modal } from "react-bootstrap";
import { login } from "../../actions/userActions";

function LoginModal() {
    // const settings: any = useSelector((store: State) => store.settings);
    const openWindows = useSelector((store: State) => store.applicationStatus.openWindows);
    const userStatus = useSelector((store: State) => store.userStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        if (openWindows.includes("login") && userStatus.installationActive) {
            dispatch(closeApplicationWindow("login"));
        }
    });

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    return (
        <>
            <Modal show={openWindows.includes("login")} onHide={() => { dispatch(closeApplicationWindow("login")) }}>
                <Modal.Header className="p-1" closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={username} onChange={(e) => { setUsername(e.target.value); }} placeholder="name@example.com" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => { setPassword(e.target.value); }} />
                        </Form.Group>
                        <Button className="w-100" onClick={() => { dispatch(login(username, password)); setUsername(""); setPassword(""); }} variant="danger">Login</Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="p-1">
                    <Button className="" variant="secondary" onClick={() => { dispatch(closeApplicationWindow("login")) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={openWindows.includes("login-error")} onHide={() => { dispatch(closeApplicationWindow("login-error")) }}>
                <Modal.Header className="p-1" closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    Error
                </Modal.Body>
                <Modal.Footer className="p-1">
                    <Button className="px-2 py-0" variant="secondary" onClick={() => { dispatch(closeApplicationWindow("login-error")) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LoginModal;