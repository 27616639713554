import React from 'react';
import './styles/App.css';
import './styles/BootstrapOverrides.css';
import DataLoader from './components/DataLoader';
import { configureStore } from '@reduxjs/toolkit'
import reducers from "./reducers";
import thunk from 'redux-thunk';
import { Provider } from "react-redux";
import AppComponent from "./components/App"

const store = configureStore({
  reducer: reducers,
  middleware: [
    thunk,
  ],
})

function App() {
  return (
    <Provider store={store}>
      <DataLoader />
      <AppComponent />
      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
        </header>
      </div> */}
    </Provider>
  );
}

export default App;
