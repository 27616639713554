import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faAngleDoubleLeft, faAngleDoubleRight, faUmbrellaBeach, faMoneyBill1Wave, faGraduationCap, faPersonRunning } from '@fortawesome/free-solid-svg-icons'
import {
    fetchAllWorklogsFromBackend as fetchAllWorklogsFromBackendAction,
    fetchAllWorklogsFromTempo as fetchAllWorklogsFromTempoAction,
} from "../../actions/worklogActions";
import { State } from "../../types";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { DayWithWorklogs } from "../../types/worklog";
import { dateToDateString, displayCumulativeHours, displayCumulativeNumber, displayHours, getBillablePercentage, getLiikuntaHours, getOpiskeluHours } from "../../helpers";

interface Props {
}
const Monthly: React.FC<Props> = () => {
    const worklogs: State["worklogs"] = useSelector((store: any) => store.worklogs);
    const dispatch: any = useDispatch();

    // const [weekOffset, setWeekOffset] = useState(1);
    const [monthOffset, setMonthOffset] = useState(1);

    const getDayWithWorklogByDate = (dateString: string): DayWithWorklogs | null => {
        return worklogs.myWorklogs.find(d => d.day === dateString) ?? null;
    }

    const getMonthWithWorklog = (offset: number): DayWithWorklogs[] => {
        let now = new Date();
        let beginningOfMonth = new Date(now.setDate(1));
        beginningOfMonth.setMonth(beginningOfMonth.getMonth() - offset);

        let endOfMonth = new Date(beginningOfMonth);
        endOfMonth.setMonth(beginningOfMonth.getMonth() + 1);
        endOfMonth.setDate(0);

        const month: DayWithWorklogs[] = [];
        for (let i = 0; i < endOfMonth.getDate(); i++) {
            let currentDay = new Date(new Date(beginningOfMonth).setDate(beginningOfMonth.getDate() + i));
            let d = getDayWithWorklogByDate(dateToDateString(currentDay));
            if (d) {
                month.push(d);
            }
        }
        return month;
    }
    const currentMonth: DayWithWorklogs[] = getMonthWithWorklog(monthOffset);

    const getTotalHours = (currentMonth: DayWithWorklogs[]): number => {
        let total = 0;
        for (let d of currentMonth) {
            total += d.totalHours ?? 0;
        }
        return total;
    }

    const getDeltaHours = (currentMonth: DayWithWorklogs[]): number => {
        let delta = 0;
        for (let d of currentMonth) {
            delta += d.deltaHours ?? 0;
        }
        return delta;
    }

    const getMonthName = (d: Date): string => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[d.getMonth()] ?? "";
    }

    useEffect(() => {
        if (worklogs.myWorklogs.length === 0) {
            dispatch(fetchAllWorklogsFromBackendAction());
        }
    }, [worklogs.myWorklogs]);

    return (
        <>
            <div className="p-2 box-grey">
                <div className="mb-3">
                    {/* <h5>Weekly</h5> */}
                    <span className="text-orange">{getMonthName(new Date(currentMonth[0]?.day))} {new Date(currentMonth[0]?.day).getFullYear()}</span>
                    <span className="ms-2 text-muted text-small">{monthOffset === 0 && "(this month)"}{monthOffset === 1 && "(last month)"}</span>
                    <br />
                    <Row>
                        <Col style={{ whiteSpace: "nowrap" }}>
                            <span>Worktime (+/-)</span><br />
                            <span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayHours(getTotalHours(currentMonth) ?? "-")}</span>
                            <span className="ms-2"></span>
                            (<span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayCumulativeHours(getDeltaHours(currentMonth) ?? "-")}</span>)
                        </Col>
                        <Col style={{ whiteSpace: "nowrap" }}>
                            <span>Cumulative</span><span className="ms-2 text-muted">(after)</span><br />
                            <span style={{ color: "#EF4119", fontSize: "16pt", fontWeight: "bold" }}>{displayCumulativeHours(currentMonth[currentMonth.length - 1]?.cumulativeDeltaHours ?? "-")}</span>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <FontAwesomeIcon icon={faMoneyBill1Wave} size={"1x"} className="me-2 text-muted" title="Billable" />
                            <span className="text-orange">{currentMonth ? getBillablePercentage(currentMonth) : ""}</span> %
                            <span className="text-small text-muted d-block">Billable (%)</span>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faGraduationCap} size={"1x"} className="me-2 text-muted" title="Studying" />
                            <span className="text-orange">{currentMonth ? getOpiskeluHours(currentMonth) : ""}</span>
                            <span className="text-small text-muted d-block">Studying (h)</span>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faPersonRunning} size={"1x"} className="me-2 text-muted" title="Sports" />
                            <span className="text-orange">{currentMonth ? getLiikuntaHours(currentMonth) : ""}</span>
                            <span className="text-small text-muted d-block">Sports (h)</span>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col>
                        <Button onClick={() => { setMonthOffset(monthOffset + 1) }} variant="secondary" className="px-1 py-0 w-100 h-100">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} size={"1x"} />
                        </Button>
                    </Col>
                    <Col style={{ whiteSpace: "nowrap" }}>
                    </Col>
                    <Col>
                        <Button disabled={monthOffset <= 0} onClick={() => { setMonthOffset(monthOffset - 1) }} variant="secondary" className="px-1 py-0 w-100 h-100">
                            <FontAwesomeIcon icon={faAngleDoubleRight} size={"1x"} />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Monthly;