import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { State } from "../../types";
import { closeApplicationWindow, openApplicationWindow } from "../../actions";
import { Button, Form, Modal } from "react-bootstrap";
import { impersonate, logout, unimpersonate } from "../../actions/userActions";
import { userHasPermission } from "../../helpers/authorizationHelper";
import { listUserIdsAction } from "../../actions/testActions";

function UserModal() {
    const openWindows = useSelector((store: State) => store.applicationStatus.openWindows);
    const userStatus = useSelector((store: State) => store.userStatus);
    const dispatch: any = useDispatch();

    const [username, setUsername] = useState<string>("");

    return (
        <>
            <Modal show={openWindows.includes("user")} onHide={() => { dispatch(closeApplicationWindow("user")) }}>
                <Modal.Header className="p-1" closeButton>
                    <Modal.Title>User</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1">
                    {userStatus.installationActive ?
                        <>
                            Logged in as <span className="text-orange">{userStatus.displayName}</span>
                            <div style={{fontSize: "10pt"}}>
                                Role: <span className="text-orange">{userStatus.role}</span>
                                <br />
                                Permissions: <span className="text-orange">{userStatus.permissions.join(", ")}</span>
                                <br />
                                Work hours calculation start day: <span className="text-orange">{userStatus.worklogsFromDate}</span>
                                <br />
                                Work hours per day: <span className="text-orange">{userStatus.hoursPerDay}</span> h
                            </div>
                            {
                                userHasPermission(userStatus, "impersonate") &&
                                <div className="mt-3">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>{userStatus.isImpersonating ? "Impersonating" : "Impersonate another user"}</Form.Label>
                                            <Form.Control disabled={userStatus.isImpersonating ? true : false} type="email" value={userStatus.isImpersonating ? userStatus.isImpersonating : username} onChange={(e) => { setUsername(e.target.value); }} placeholder="name@example.com" />
                                        </Form.Group>
                                        {
                                            userStatus.isImpersonating ?
                                                <Button onClick={() => { dispatch(unimpersonate()); }} variant="danger">Stop Impersonating</Button>
                                                :
                                                <Button onClick={() => { dispatch(impersonate(username)); setUsername(""); }} variant="danger">Impersonate</Button>
                                        }
                                    </Form>
                                </div>
                            }
                            {
                                userHasPermission(userStatus, "devtools") &&
                                <div className="mt-3">
                                    <Button onClick={() => { dispatch(listUserIdsAction()); }} variant="danger">Log accountIds from "Kaikki" team</Button>
                                </div>
                            }
                        </>
                        :
                        <>
                            <div>Not logged in.</div>
                            <Button onClick={() => { dispatch(openApplicationWindow("login")) }} className="w-100" variant="danger">Login</Button>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer className="p-1">
                    <Button onClick={() => { dispatch(logout()) }} variant="danger">Logout</Button>
                    <Button className="" variant="secondary" onClick={() => { dispatch(closeApplicationWindow("user")) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default UserModal;